import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from './../Navbar';
import './../../styles/all.scss'
import PropTypes from "prop-types";
import {useGlobalState} from "./../../store/app";
import Footer from "../Footer";
import useSiteMetadata from "../SiteMetadata";

const ListLayout = ({ pageName, children }) => {
  const [navOpen, setNavOpen] = useGlobalState('navOpen');
  const { title, description } = useSiteMetadata();
  const navBar = React.createRef();

  const handleNavBarOpen = (open) => {
      setNavOpen(open);
  };

  return (
      <div className={`p-list p-${pageName}`}>
          <Helmet>
              <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
              <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1"/>
              <meta name="description" content={description}/>
              <title>{title}</title>
          </Helmet>

          <Navbar onToggle={handleNavBarOpen} ref={navBar} />

          <main className={"l-container l-container--indented-constrained-wide"}>
              {children}
          </main>

          <Footer layout={"l-container--indented-constrained-wide"} />
      </div>
  )
}

ListLayout.propTypes = {
    pageName: PropTypes.string
}

export default ListLayout
