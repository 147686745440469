import React, {useState} from 'react'

import ListLayout from '../../components/layout/ListLayout';
import BlogRoll from '../../components/BlogRoll'
import Tag from "../../components/Tag";
import {graphql} from "gatsby";

export default ({data}) => {
    const posts = data.allMarkdownRemark.edges;
    const [activePosts, setActivePosts] = useState(posts);
    const [postTypes, setPostTypes] = useState([]);

    const filterPosts = (msg) => {
        let newVal = msg.active ?
            [...postTypes, msg.label] : postTypes.filter(t => t !== msg.label)

        setPostTypes(newVal);
        setActivePosts(posts.filter(
            p => newVal.length === 0 || newVal.includes(p.node.frontmatter.tag))
        );
    }

    return (
        <ListLayout pageName={"blog"}>
            <div className={"c-blogroll--head"}>
                <h1 className={"copy-header copy-header--xl"}>
                    Blog
                </h1>

                <p>I mostly write about PHP & Javascript so if that's something your interested in, check out the posts below</p>

                <div className={"p-list--nav l-flex"}>
                    <Tag label={"Frontend"} onClick={filterPosts}/>
                    <Tag label={"PHP"} onClick={filterPosts}/>
                    <Tag label={"Programming"} onClick={filterPosts}/>
                    <Tag label={"Misc"} onClick={filterPosts}/>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="content">
                        <BlogRoll posts={activePosts} />
                    </div>
                </div>
            </section>
        </ListLayout>
    );
}

export const query = graphql`
       query BlogQuery {
        allMarkdownRemark(
            sort: {order: DESC, fields: [frontmatter___date]}, 
            filter: {frontmatter: {templateKey: {eq: "blog-post"}}}
        ) {
          edges {
            node {
              excerpt(pruneLength: 70)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                tag
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 560, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }`;
