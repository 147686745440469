import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Button from "./Button";
import Tag from "./Tag";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BlogRoll = ({posts}) => {
  return  (
      <div className="c-blogroll">
        {posts &&
        posts.map(({ node: post }) => (
            <div key={post.id}>
              <article className={`c-blogroll--post l-flex ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
              }`}>
                <div>
                  {post.frontmatter.featuredimage ? (
                      <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                      />
                  ) : null}
                </div>
                <div>
                  <header>
                    <Tag label={post.frontmatter.tag}></Tag>

                    <p className="copy-header copy-header--s">
                      <Link className="title has-text-primary is-size-4 no-border"
                            to={post.fields.slug}>
                        {post.frontmatter.title}
                      </Link>
                    </p>
                  </header>

                  <p>
                    {post.excerpt}
                  </p>

                  <Button type={"text"} link={post.fields.slug}>
                    <span>Read post</span> <FontAwesomeIcon icon={faArrowRight}  size="s" />
                  </Button>
                </div>
              </article>
            </div>
        ))}
      </div>
  )
}

BlogRoll.propTypes = {
  posts: PropTypes.array,
}

export default BlogRoll;
